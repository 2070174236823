@import "~_common";

.container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;
  text-align: center;
  flex-direction: column;
}

.cta {
  font-size: 3rem;
  font-family: LatoWebLight;
  font-style: italic;
}
