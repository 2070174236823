$border-dark-navy: #243e57;
$white: #fff;
$black: #000;
$yellow: #e5d68f;
$yellow-transparent: rgba(229, 214, 143, 0.1);
$navy: #062340;
$navy-grey: #666;
$light-grey: #e1e1e1;

:global {
  .quote {
    border: solid 1px $light-grey;
    position: relative;
    padding: 2rem 4rem;
    font-style: italic;

    &::before {
      position: absolute;
      top: 1rem;
      left: 50%;
      transform: translateX(-50%);
      color: $navy;
      font-size: 8rem;
      content: "“";
    }

    .signature {
      font-size: 1rem;
      font-family: "LatoWebBold";
      color: $navy;
      text-align: center;
    }
  }
}
