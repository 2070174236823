@import "~_common";

@mixin theme($color: $navy, $hoverColor: $yellow) {
  border-color: $color;
  color: $color;

  &:before,
  &:after {
    border-color: $color;
  }

  &:hover {
    color: $hoverColor;
    border-color: $hoverColor;

    &:before,
    &:after {
      border-color: $hoverColor;
    }
  }
}

.btn {
  background: transparent;
  border-style: none solid solid;
  border-width: 1px;
  padding: 2rem 5rem;
  font-size: 2.125rem;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
  font-family: LatoWebLight;

  &:before,
  &:after {
    content: "";
    border-style: solid none none;
    border-width: 1px;

    display: block;
    position: absolute;
    top: 0;
  }

  &:before {
    left: 0;
    width: 55%;
  }
  &:after {
    right: 0;
    width: 20%;
  }

  @include theme();

  &.yellow {
    @include theme($yellow, $navy);
  }

  &.yellowWhite {
    @include theme($yellow, $white);
  }

  &.cta {
    font-size: 3rem;
    padding: 3rem 7rem;
  }
}
