@import "~_common";

.container {
  color: $white;
  background: $black;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 4;
  transition: height 1s;
  height: 3rem;
  width: 7rem;
  overflow: hidden;
  padding: 0.2rem;

  &.active {
    height: 7rem;
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}

$soundOffBg: url("../../assets/images/soundOff.svg"); //   todo soundOff
$soundOnBg: url("../../assets/images/soundOn.svg");

$soundMenuBg: url("../../assets/images/soundMenuOff.svg");
$soundMenuActiveBg: url("../../assets/images/soundMenuOn.svg"); // todo

$musicBg: url("../../assets/images/musicOff.png");
$musicActiveBg: url("../../assets/images/musicOn.png");

$effectsBg: url("../../assets/images/effectsOff.png");
$effectsActiveBg: url("../../assets/images/effectsOn.png");

@mixin musicBtn($bgImg, $bgActiveImg) {
  background-image: $bgImg;

  //   &:hover {
  //     background-image: $bgActiveImg;
  //   }

  &.active {
    background-image: $bgActiveImg;

    // &:hover {
    //   background-image: $bgImg;
    // }
  }
}

.soundBtn,
.soundMenuBtn,
.audioBtn {
  width: 2.4rem;
  height: 2rem;
  margin: 0.5rem;
  background-repeat: no-repeat;
  background-size: auto;
  cursor: pointer;
}

.audioBtn {
  width: 2rem;
  height: 3rem;
}

.soundBtn {
  @include musicBtn($soundOffBg, $soundOnBg);
}

.soundMenuBtn {
  @include musicBtn($soundMenuBg, $soundMenuActiveBg);
}

.music {
  @include musicBtn($musicBg, $musicActiveBg);
}

.effects {
  @include musicBtn($effectsBg, $effectsActiveBg);
}
