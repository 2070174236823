@import "~_common";

@mixin transitionTooltip($time) {
  transition: left $time, top $time, width $time, height $time, bottom $time,
    right $time, background-color $time, margin $time;
}

.container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.magnifierSwitch {
  position: absolute;
  top: 4rem;
  left: 2rem;
  cursor: pointer;
  z-index: 3;
  color: $navy;

  &:hover {
    opacity: 0.5;
  }
}

.slider {
  overflow-y: clip;
  overflow-x: auto;
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 2;
  padding-top: 4rem;

  svg {
    height: 75%; //TODO - breakdown points; unify with magnifier
    position: absolute;
    bottom: 0;

    path {
      pointer-events: all;
    }
  }
}

.embeddedImg {
  isolation: isolate;
}

.highlightedLayer {
  fill: none;

  &.active,
  &:hover {
    fill: $yellow-transparent;
    stroke: $yellow;
  }
}

.button {
  position: absolute !important;
  bottom: 1rem;
  z-index: 2;
  left: 50%;
  transform: translateX(-50%);
}

.preview {
  height: 20%;
  position: absolute;
  top: 2rem;
  right: 50%;
  transform: translateX(50%);

  img {
    height: 100%;
  }

  .marker,
  .markerBg {
    height: 100%;
    position: absolute;
  }

  .marker {
    &:hover {
      background: $yellow-transparent;
      cursor: grab;
    }
  }

  .markerBg {
    background-color: $white;
    opacity: 0.5;
  }
}
