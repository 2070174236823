@import "~_common";

.title {
  font-size: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-weight: normal;
  margin: 4rem 0;
  width: 100%;

  &::before,
  &::after {
    content: "";
    border-top: solid 2px $navy;
    width: 25%;
    height: 1px;
    display: block;
  }
  &:before {
    left: -40%;
  }
  &:after {
    right: -40%;
  }

  &.main {
    font-size: 6rem;
    margin: 1rem 0 4rem 0;
    font-family: LatoWeb;

    &::before,
    &::after {
      border-color: $yellow;
    }
  }
}
