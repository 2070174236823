@import "~_common";

.container {
  padding: 2rem;
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: justify;
}

.dataContainer {
  padding: 2rem;

  section {
    padding: 2rem 1rem;
    text-align: center;

    h2 {
      text-align: center;
    }

    a {
      text-decoration: none;
    }
  }

  dt {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 1rem 0;
  }

  dd {
    font-size: 1.2rem;
  }

  .logo {
    display: block;
    margin: 2rem auto;
  }
}

.content {
  color: $navy-grey;
  font-size: 1.3125rem;

  > * {
    width: 70%;
    margin: 3rem auto;
  }

  img {
    width: 80%;
    max-width: 1400px;
    display: block;
    margin: 0 auto;
  }
}
