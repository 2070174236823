.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.preview {
  height: 20%;
  position: relative;

  img {
    height: 100%;
  }

  .glass {
    position: absolute;
    border: solid 1px red;
    cursor: grab;
  }
}

.magnified {
  height: 75%; //TODO - unify with panorama
  width: 100%;
  position: relative;
  overflow: hidden;
  img {
    position: absolute;
  }
}
