@import "~_common";

.container {
  display: flex;
  color: $navy;
  height: 100vh;
  max-width: 100%;
  overflow: auto;
  flex-direction: column;
  // justify-content: space-around;
  align-items: center;
  padding: 4rem 0;
}

.text {
  font-size: 1.5rem;
  max-width: 42rem;
  text-align: justify;
  line-height: 2.2rem;
  margin-bottom: 5rem;
}

.images {
  display: flex;
  justify-content: center;
  align-items: center;
}
