@import "~_common";

@mixin transitionExtendTooltip($time) {
  transition: left $time, top $time, width $time, height $time, bottom $time,
    right $time, background-color $time, margin $time, opacity $time;
}

.tooltip,
.tooltipContent {
  border-color: $border-dark-navy;
  position: absolute;
  z-index: 3;
  border-width: 2px;
}

.tooltipContent {
  width: 21rem;
  border-style: solid none none;
  color: $navy-grey;
  font-size: 1.3125rem;
}

.close {
  width: 1rem;
  height: 1rem;
  position: absolute;
  z-index: 2;
  right: 1rem;
  top: 1rem;
  opacity: 0.3;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  &:before,
  &:after {
    position: absolute;
    left: 0.5rem;
    content: " ";
    height: 1rem;
    width: 2px;
    background-color: #333;
  }

  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
}

.tooltipText {
  padding: 2rem;
  margin-bottom: 1.3125rem;
  opacity: 1;
  background: rgba(255, 255, 255, 0.8);
  transition: opacity 2s 2s;
  font-size: 1.5rem;

  > div {
    margin-bottom: 1rem;
  }

  &.hidden {
    transition: opacity 0s 0s;

    opacity: 0;
    height: 0;
    padding: 0;
    margin: 0;
  }
}

.tooltipTextExtended {
  padding: 2rem;
  overflow: auto;
  transition: opacity 2s 2s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: justify;
  line-height: 2rem;

  img {
    width: 80%;
    max-width: 1400px;
    display: block;
    margin: 0 auto;
  }

  & > div {
    > * {
      width: 70%;
      margin: 3rem auto;
    }
  }

  &.hidden {
    height: 0;
    transition: opacity 0s 0s;
    opacity: 0;
  }

  &.visible {
    height: auto;
    opacity: 1;
  }
}

.tooltip {
  width: 1px;
  border-style: none none none solid;

  &.fading {
    opacity: 0;
  }

  &:after {
    content: "";
    position: absolute;
    width: 26px;
    height: 26px;
    border: solid 2px $border-dark-navy;
    bottom: -26px;
    left: -13px;
    border-radius: 50%;
  }

  &.inverted {
    border-style: none solid none none;
    .tooltipContent {
      transform: translateX(-100%);
    }
  }

  &.expanded {
    border-top-style: solid;
    right: 0;
    width: calc(100% - 4rem);
    height: calc(100vh - 8rem) !important;
    margin: 1rem 2rem 4rem 2rem;
    background-color: rgba(255, 255, 255, 1);

    .tooltipContent {
      transform: translateX(0);
      border-top: none;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  &.animated {
    @include transitionExtendTooltip(2s);

    .tooltipContent {
      @include transitionExtendTooltip(2s);
    }
  }
}
