html {
  box-sizing: border-box;
  font-family: LatoWebLight;
  font-size: 16px;
  margin: 0;
  padding: 0;
}

@media (max-width: 500px) {
  html {
    font-size: 12px;
  }
}

body {
  min-width: 300px;
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.container {
  height: 100vh;
  overflow: hidden;
}
