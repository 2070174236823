@import "~_common";

.menu {
  position: fixed;
  z-index: 4;
  top: 0;
  list-style: none;
  color: $white;
  background: $black;
  margin: 0;
  padding: 0;
  width: calc(100% - 7rem);
  display: flex;
  align-items: center;
  transition: width 2s, left 2s, right 2s;
  height: 3rem;
  overflow: hidden;

  li {
    padding: 0.5rem 2rem;
    cursor: pointer;
    white-space: nowrap;

    &:hover,
    &.active {
      opacity: 0.5;
    }

    &.hide {
      margin-right: auto;
      padding: 0.5rem;
    }
  }

  &.hidden {
    width: 3.5rem;
    justify-content: center;
    transition: width 1s;

    li {
      display: none;

      &.hide {
        display: initial;
      }
    }
  }
}
